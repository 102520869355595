import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as loadingActions from '../../../state/actions/loading'

import BonPlansJeunesSingleItem from "./BonPlansJeunesSingleItem"

class BonPlansJeunesSingle extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)

		const { urlSingle } = this.props

		// Liste des filtres actifs
		this.state = {
			urlSingle: urlSingle ? urlSingle.trim() : '',
			post: []
		}
	}

	componentDidMount() {
		this._isMounted = true
		const { loadingOn } = this.props

		this.launchSingle()
		loadingOn('BonsPlansJeunesSingle')
	}

	componentWillUnmount() {
		const { loadingOff } = this.props

		loadingOff('BonsPlansJeunesSingle')
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevState.urlSingle !== this.state.urlSingle && this.launchSingle()
	}

	launchSingle() {
		const { loadingOff } = this.props
		const { urlSingle } = this.state
		let id = urlSingle.split('-')
		id = id[id.length - 1]

		if (parseInt(id) > 0) {
			const singleQuery = 'https://carto.ville-roubaix.fr/server/rest/services/OpenData/SOLIDARITE/FeatureServer/32/query?where=&objectIds=' + id + '&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=json';
			fetch(singleQuery,
				{
					headers: {
					},
					method: "GET"
				}
			).then(response => {
				if (response.status && response.status > 200) {
					console.warn(response)
				} else {
					return response.json()
				}
			}).then(({ features }) => {
				this._isMounted && this.setState({
					post: features[0].attributes
				})
				loadingOff('BonsPlansJeunesSingle')
			}).catch(function (error) {
				console.warn("error")
				console.warn(error)
			})
		}
	}

	render() {
		const { location } = this.props
		const { post } = this.state

		return (
			<div>
				<div className="offline-fallback-fix-no-idea-why"></div>
				<div className="bons-plans-jeunes-single">
					<BonPlansJeunesSingleItem
						shareUrl={location.href}
						postItem={post}
					/>
				</div>
			</div>
		)
	}
}

export default connect(null, loadingActions)(BonPlansJeunesSingle)
