import React from 'react'
import { Router } from "@reach/router"

import BonPlansJeunesList from '../../../../components/BonPlansJeunes/List/BonPlansJeunesList'
import BonPlansJeunesSingle from '../../../../components/BonPlansJeunes/Single/BonPlansJeunesSingle'

const BonsPlansJeunesResults = ({data}) =>
	<div>
		{typeof window !== 'undefined'  &&
			<Router>
				<BonPlansJeunesList path="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/annuaire-bons-plans-jeunes/:myFilterType/:myFilterValue" />
				<BonPlansJeunesList path="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/annuaire-bons-plans-jeunes/" />
				<BonPlansJeunesSingle path="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/annuaire-bons-plans-jeunes/:urlSingle" />
			</Router>
		}
	</div>

export default BonsPlansJeunesResults
