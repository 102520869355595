import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import * as loadingActions from '../../../state/actions/loading'

import Config from '../../../config/Config.json'
import Config_local from '../../../config/Config_local.json'

import BonsPlansJeunesForm from "./../Form/BonsPlansJeunesForm"
import BonsPlansJeunesFilterReset from './../Form/BonsPlansJeunesFilterReset';

import BonsPlansJeunesBreadcrumb from './../BonsPlansJeunesBreadcrumb'
import BonsPlansJeunesListItem from './BonsPlansJeunesListItem'

import Pagination from "../../Pagination/Pagination"

const config = { ...Config, ...Config_local }

class BonsPlansJeunesList extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)
		this.state = {
			mysearch: '',
			bonsPLansJeunesFilterFilterActiveThemes: '',
			bonsPLansJeunesFilterFilterActivePublics: '',
			posts: 0,
			page: localStorage.getItem('BonsPlanJeunesFilterPage') || 0,
		}
	}

	componentDidMount() {
		this._isMounted = true
		const { loadingOn } = this.props
		loadingOn('BonsPlansJeunesList')
		this.setState({
			mysearch: localStorage.getItem('BonsPLansJeunesFilterMySearch') || '',
			bonsPLansJeunesFilterFilterActiveThemes: localStorage.getItem('BonsPLansJeunesFilterFilterActiveThemes') || '',
			bonsPLansJeunesFilterFilterActivePublics: localStorage.getItem('BonsPLansJeunesFilterFilterActivePublics') || '',
			bonsPlansJeunesResetShow: localStorage.getItem('BonsPLansJeunesFilterMySearch') ||
				localStorage.getItem('BonsPLansJeunesFilterFilterActiveThemes') ||
				localStorage.getItem('BonsPLansJeunesFilterFilterActivePublics') ||
				false,
		}, () => {
			this.launchSearch()
		})

	}

	componentWillUnmount() {
		const { loadingOff } = this.props
		this._isMounted = false
		loadingOff('BonsPlansJeunesList')
	}

	launchSearch = () => {
		const page = ((this.state.page - 1) >= 0 ? ((this.state.page - 1) * 12) : 0)
		let queryOpenData = "?where=";
		let where = "1=1";
		if (this.state.mysearch && this.state.mysearch.length > 0) {
			where = where + ' AND ( LOWER(nom_dispositif) LIKE LOWER(\'%25' + encodeURI(this.state.mysearch) + '%25\') )';
		}
		if (this.state.bonsPLansJeunesFilterFilterActiveThemes && this.state.bonsPLansJeunesFilterFilterActiveThemes.length > 0) {
			where = where + ' AND LOWER(thematique)=LOWER(\'' + encodeURI(this.state.bonsPLansJeunesFilterFilterActiveThemes) + '\')';
		}
		if (this.state.bonsPLansJeunesFilterFilterActivePublics && this.state.bonsPLansJeunesFilterFilterActivePublics.length > 0) {
			where = where + ' AND tranche_d_age=\'' + encodeURI(this.state.bonsPLansJeunesFilterFilterActivePublics) + '\'';
		}

		queryOpenData = queryOpenData + where + '&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields="*"&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=';
		fetch(
			"https://carto.ville-roubaix.fr/server/rest/services/OpenData/SOLIDARITE/FeatureServer/32/query" + queryOpenData + "&f=json",
			{
				headers: {},
				method: "GET",
			}
		)
			.then((response) => {
				if (response.status && response.status > 200) {
					console.warn(response);
				} else {
					return response.json();
				}
			})
			.then((results) => {
				const { nhits, offset, limit, features } = results;
				const { loadingOff } = this.props;

				this._isMounted &&
					this.setState({
						totals: nhits,
						offset,
						limit,
						page: localStorage.getItem("BonsPLansJeunesFilterPage") || 0,
						posts: features,
					});
				this.bonsPlansJeunesOnPaginateScroll();
				loadingOff("BonsPlansJeunesList");
			})
			.catch((error) => console.warn("error", error));
	}

	bonsPlansJeunesOnKeyDown = ({ target }) => {
		if (!this.onKeyDown) {
			this.onKeyDown = true
		}
	}

	bonsPlansJeunesOnKeyUp = ({ keyCode, target }) => {
		this.onKeyDown = false
		if (keyCode === 13) {
			this.launchSearch()
		} else {
			const { value } = target
			this.setState({
				mysearch: value.trim()
			}, () => {
				localStorage.setItem('BonsPLansJeunesFilterMySearch', value.trim())
				this.launchSearch()
			})
		}
	}

	bonsPlansJeunesOnChangeFilterThemes = (val) => {
		this.setState({
			bonsPLansJeunesFilterFilterActiveThemes: val.trim(),
			bonsPlansJeunesResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('BonsPLansJeunesFilterFilterActiveThemes', val.trim());
			localStorage.setItem('BonsPLansJeunesFilterPage', 0);
			localStorage.setItem('BonsPLansJeunesFilterResetShow', true);
			this.launchSearch()
		})
	}

	bonsPlansJeunesOnChangeFilterPublics = (val) => {
		this.setState({
			bonsPLansJeunesFilterFilterActivePublics: val.trim(),
			bonsPlansJeunesResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('BonsPLansJeunesFilterFilterActivePublics', val.trim());
			localStorage.setItem('BonsPLansJeunesFilterPage', 0);
			localStorage.setItem('BonsPLansJeunesFilterResetShow', true);
			this.launchSearch()
		})
	}

	bonsPlansJeunesOnSubmit = (e) => {
		e.preventDefault()
		this.launchSearch()
	}

	bonsPlansJeunesOnClickPaginate = (e) => {
		e.preventDefault()
		this.setState({
			page: e.target.getAttribute('data-num-page')
		}, () => {
			localStorage.setItem('BonsPLansJeunesFilterPage', this.state.page);
			this.bonsPlansJeunesOnPaginateScroll()
			this.launchSearch()
		})
	}


	bonsPlansJeunesOnPaginateScroll = () => {
		if (typeof window !== 'undefined') {
			window.scroll(0, document.querySelector(".bons-plans-jeunes-filters").getBoundingClientRect().top)
			window.scroll(0, document.querySelector(".bons-plans-jeunes-filters").getBoundingClientRect().top)
		}
	}

	bonsPlansJeunesOnReset = (e) => {
		e.preventDefault()
		this.setState({
			mysearch: '',
			bonsPLansJeunesFilterFilterActiveThemes: '',
			bonsPLansJeunesFilterFilterActivePublics: '',
			posts: 0,
			page: 0,
			bonsPlansJeunesResetShow: false,
		}, () => {
			localStorage.setItem('BonsPLansJeunesFilterMySearch', '')
			localStorage.setItem('BonsPLansJeunesFilterFilterActiveThemes', '');
			localStorage.setItem('BonsPLansJeunesFilterFilterActivePublics', '');
			localStorage.setItem('BonsPLansJeunesFilterPage', 0);
			localStorage.setItem('BonsPLansJeunesFilterResetShow', false);
			this.launchSearch()
		})
	}

	render() {
		const { posts, totals, page } = this.state
		return (
			<div>
				<Container>
					<Helmet>
						<title>Bons plans jeunes</title>
					</Helmet>
					<div className="offline-fallback-fix-no-idea-why"></div>
					<BonsPlansJeunesBreadcrumb />
					<div id="bons-plans-jeunes" className="bons-plans-jeunes">
						<BonsPlansJeunesForm
							bonsPLansJeunesFilterMySearch={this.state.mysearch}
							bonsPLansJeunesFilterFilterActiveThemes={this.state.bonsPLansJeunesFilterFilterActiveThemes}
							OnChangeFilterThemes={this.bonsPlansJeunesOnChangeFilterThemes}
							bonsPLansJeunesFilterFilterActivePublics={this.state.bonsPLansJeunesFilterFilterActivePublics}
							OnChangeFilterPublics={this.bonsPlansJeunesOnChangeFilterPublics}
							onSubmit={this.bonsPlansJeunesOnSubmit}
							placeholder="Chercher un bon plan"
							type="text"
							onKeyDown={this.bonsPlansJeunesOnKeyDown}
							onKeyUp={this.bonsPlansJeunesOnKeyUp}
						/>

						<h1 className="bons-plans-jeunes-result">
							{posts && posts.length ?
								`${posts.length} résultat${posts.length > 1 ? 's' : ''} pour votre recherche ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}` :
								`Pas de résultat pour votre recherche  ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}`
							}
						</h1>
						<BonsPlansJeunesFilterReset BonsPlansJeunesOnReset={this.bonsPlansJeunesOnReset} BonsPlansJeunesResetShow={this.state.bonsPlansJeunesResetShow} />
						<ul id="bons-plans-jeunes-list" className="bons-plans-jeunes-list list-unstyled">
							{posts && posts.length > 0 && posts.map((post, index) =>
								<li className="bons-plans-jeunes-list-item" key={index}>
									<BonsPlansJeunesListItem
										postIndex={index}
										postItem={post.attributes}
									/>
									{post.attributes.nom_de_la_structure}
								</li>
							)}
							{posts && !posts.length > 0 && Array(4).fill('').map((u, index) => <li className="agenda-list-item" key={index} />)}
						</ul>

						<div className="bons-plans-jeunes-list-footer container">
							<div className="row">
								<div className="col-sm d-flex justify-content-start">
									<p className="generic-filter-button bons-plans-jeunes-filter-button"><a rel="noopener noreferrer" className="btn btn-primary btn-lg btn-pink-3" href="https://services.ville-roubaix.fr/demarches/proposition_bpj" target="_blank">Proposer un bon plan jeune</a></p>
								</div>
								<div className="col-sm d-flex align-items-center">
									<Pagination
										offset={config.bonsPlansJeunesPagination.offset}
										limit={config.bonsPlansJeunesPagination.limit}
										totals={totals}
										currentPage={page}
										OnClickPaginate={this.bonsPlansJeunesOnClickPaginate}
									/>
								</div>
								<div className="col-sm"></div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

export default connect(null, loadingActions)(BonsPlansJeunesList)