import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const BonsPLansJeunesFormFilterPublics = ({bonsPLansJeunesFilterFilterActivePublics, OnChangeFilterPublics, AgendaFilterActiveOther}) => 
	<Select 
		className="generic-filters-select generic-filters-select-publics bons-plans-jeunes-filters-select bons-plans-jeunes-filters-select-publics" 
		name="agenda-filters-category" 
		placeholder="Public" 
		options={SELECTS['publics']} 
		onChange={OnChangeFilterPublics}
		value={bonsPLansJeunesFilterFilterActivePublics}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default BonsPLansJeunesFormFilterPublics
