exports.themes = [
	{ value: '', label: 'Thématiques', className: 'option-theme' },
	{ value: 'Se loger, se déplacer', label: 'Se loger, se déplacer', className: 'option-theme' },
	{ value: 'S’insérer, agir, s’impliquer', label: 'S’insérer, agir, s’impliquer', className: 'option-theme' },
	{ value: 'S\'orienter, étudier, se former', label: 'S\'orienter, étudier, se former', className: 'option-theme' },
	{ value: 'Prendre soin de soi', label: 'Prendre soin de soi', className: 'option-theme' },
];
exports.publics = [
	{ value: '', label: 'Public', className: 'option-public' },
	{ value: '11-15 ans', label: '11-15 ans', className: 'option-public' },
	{ value: '11-30 ans', label: '11-30 ans', className: 'option-public' },
	{ value: '16-30 ans', label: '16-30 ans', className: 'option-public' },
];