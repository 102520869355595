import React from 'react';
import { Link } from 'gatsby'
import BonsPLansJeunesFormFilterThemes from './BonsPLansJeunesFormFilterThemes';
import BonsPLansJeunesFormFilterPublics from './BonsPLansJeunesFormFilterPublics';

const BonsPlansJeunesForm = ({bonsPLansJeunesFilterMySearch, bonsPLansJeunesFilterFilterActiveThemes, OnChangeFilterThemes, bonsPLansJeunesFilterFilterActivePublics, OnChangeFilterPublics, onSubmit, ...rest}) => (
	<form id="bons-plans-jeunes-filters" className="generic-filters bons-plans-jeunes-filters" onSubmit={onSubmit}>
		<div className="generic-filters-header bons-plans-jeunes-filters-header">
			<h1 className="generic-filters-header-title bons-plans-jeunes-filters-header-title"><span>Bons Plans</span> Jeunes</h1>
			<div className="bons-plans-jeunes-filters-header-catalog">
            	<p className="generic-filter-button bons-plans-jeunes-filter-button"><Link rel="noopener noreferrer" className="btn btn-primary btn-lg btn-pink-3" to="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/catalogue-de-solutions-obligation-formation-jeunes-16-18-ans/" >Catalogue de solutions : Obligation de formation 16-18 ans</Link></p>
			</div>
		</div>
		<div className="generic-fields bons-plans-jeunes-filters-fields">
			<BonsPLansJeunesFormFilterThemes 
				bonsPLansJeunesFilterFilterActiveThemes={bonsPLansJeunesFilterFilterActiveThemes} 
				OnChangeFilterThemes={OnChangeFilterThemes} 
			/>
			<BonsPLansJeunesFormFilterPublics 
				bonsPLansJeunesFilterFilterActivePublics={bonsPLansJeunesFilterFilterActivePublics} 
				OnChangeFilterPublics={OnChangeFilterPublics}
			/>
			<div className="generic-filters-search bons-plans-jeunes-filters-search">
				<div className="generic-filters-search-group bons-plans-jeunes-filters-search-group">
					<div className="generic-filters-search-text bons-plans-jeunes-filters-text" ><input id="bons-plans-jeunes-filters-text" {...rest} /></div>
					<div className="generic-filters-search-submit bons-plans-jeunes-filters-submit" onClick={onSubmit} onKeyPress={onSubmit} role="button" tabIndex={0}>
						<input id="bons-plans-jeunes-filters-submit" type="submit" value="Go" />
					</div>
				</div>
			</div>
			<div className="generic-filters-link bons-plans-jeunes-filters-link">
				<p className="generic-filter-button bons-plans-jeunes-filter-button"><Link rel="noopener noreferrer" className="btn btn-primary btn-lg btn-pink-3" to="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/videos-bons-plans-jeunes/" >Vidéos Bons Plans Jeunes</Link></p>
				<p className="generic-filter-button bons-plans-jeunes-filter-button"><a rel="noopener noreferrer" className="btn btn-primary btn-lg btn-pink-3" target="_blank" href="https://services.ville-roubaix.fr/demarches/bpj" >Contact</a></p>
			</div>
		</div>
	</form>
)

export default BonsPlansJeunesForm;
