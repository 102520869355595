import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const BonsPLansJeunesFormFilterThemes = ({bonsPLansJeunesFilterFilterActiveThemes, OnChangeFilterThemes, AgendaFilterActiveOther}) => 
	<Select 
		className="generic-filters-select generic-filters-select-category agenda-filters-select agenda-filters-select-category" 
		name="agenda-filters-category" 
		placeholder="Thématiques" 
		options={SELECTS['themes']} 
		onChange={OnChangeFilterThemes}
		value={bonsPLansJeunesFilterFilterActiveThemes}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default BonsPLansJeunesFormFilterThemes
